import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"

import "@fontsource/catamaran/400.css"
import "@fontsource/catamaran/500.css"
import "@fontsource/catamaran/600.css"
import "@fontsource/catamaran/700.css"
import "@fontsource/catamaran/800.css"

import React from "react"
import ReCaptchaV3 from "./src/components/common/ReCaptchaV3"

// eslint-disable-next-line no-unused-vars
export const wrapPageElement = ({ element, props }) => {
  return <ReCaptchaV3>{element}</ReCaptchaV3>
}
