import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const ReCaptchaV3 = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LfvbO4pAAAAAG5znloPfiUMUbJWynebvtdvWe1k"
    useEnterprise={true}
  >
    {children}
  </GoogleReCaptchaProvider>
)

export default ReCaptchaV3
